import { useEffect, useState } from "react";
import { Flex, Table } from "antd";
import { Chart } from "react-google-charts";
import { SkiSchoolT } from "../SkiSchool/skiTypes/skiTypes";
import { OtherActivitiesT } from "../OtherActivities/OtherActivities";
import { ParaglidingT } from "../Paragliding/Paragliding";
import { ContactT } from "../Contact/contactComponent";
import { AmountsCard } from "./AmountsCard";
import { NumberOfOrdersCard } from "./NumberOfOrdersCard";
import { MdOutlineSnowmobile } from "react-icons/md";
import { LiaSkiingSolid } from "react-icons/lia";
import { PiParachuteDuotone } from "react-icons/pi";
import { FiUsers } from "react-icons/fi";
import { TableWrapper } from "../TableWrapper";
import { recentOrdersColumns } from "./TableColumns/TableColumns";
import { DashboardSkeleton } from "./Skeleton";
import classes from "./dashboard.module.css";
import { CurrencyT } from "src/types";

const initialInfo: {
  contactmessages: ContactT[];
  otherActivitiesBookings: OtherActivitiesT[];
  paraglidingBookings: ParaglidingT[];
  skischoolBookings: SkiSchoolT[];
} = {
  contactmessages: [],
  otherActivitiesBookings: [],
  paraglidingBookings: [],
  skischoolBookings: [],
};

const Dashboard = () => {
  const [allInfo, setAllInfo] = useState<typeof initialInfo>(initialInfo);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [todayOrders, setTodayOrders] = useState<{
    usd: number;
    gel: number;
  }>();
  const [yesterdayOrders, setYesterdayOrders] = useState<{
    usd: number;
    gel: number;
  }>();
  const [thisMonthOrders, setThisMonthOrders] = useState<{
    usd: number;
    gel: number;
  }>();
  const [allTimeOrders, setAllTimeOrders] = useState<{
    usd: number;
    gel: number;
  }>();

  const orderFilterer = (order: OtherActivitiesT[]) => {
    const ordersPrice = order.reduce(
      (obj, amount) => {
        obj.usd += amount.currency.discountUSD
          ? amount.currency.discountUSD
          : amount.currency.usd;
        obj.gel += amount.currency.discountGEL
          ? amount.currency.discountGEL
          : amount.currency.gel;
        return obj;
      },
      {
        usd: 0,
        gel: 0,
      }
    );

    return ordersPrice;
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/all`);

      const resData = await response.json();

      setTodayOrders(orderFilterer(resData.todayOrders));
      setYesterdayOrders(orderFilterer(resData.yesterdayOrders));

      setThisMonthOrders(orderFilterer(resData.thisMonthOrders));
      setAllTimeOrders(orderFilterer(resData.allTimeOrders));
      setAllInfo(resData.fullData);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setError(err as string | null);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // console.log(allInfo);

  // ------------------------------------setting all time amounts-------------------------------

  // -----------------------------------------------------------------------------------------------

  if (loading) {
    return <DashboardSkeleton />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const numberOfTotalOrders = [
    {
      id: 1,
      icon: <LiaSkiingSolid />,
      title: "Ski School Total Orders",
      numberOfOrders: allInfo ? allInfo?.skischoolBookings.length : 0,
    },
    {
      id: 2,
      icon: <PiParachuteDuotone />,
      title: "Paragliding Total Orders",
      numberOfOrders: allInfo ? allInfo?.paraglidingBookings.length : 0,
    },
    {
      id: 3,
      icon: <MdOutlineSnowmobile />,
      title: "Other Activities Total Orders",
      numberOfOrders: allInfo ? allInfo?.otherActivitiesBookings.length : 0,
    },
    {
      id: 4,
      icon: <FiUsers />,
      title: "Total contact Messages",
      numberOfOrders: allInfo ? allInfo?.contactmessages.length : 0,
    },
  ];

  const tableDataTest: typeof allInfo.otherActivitiesBookings =
    allInfo?.otherActivitiesBookings.map((item) => ({
      ...item,
      key: item._id,
      date: item.date.split("T")[0],
    }));

  return (
    <Flex vertical gap={48}>
      <Flex vertical gap={36}>
        <h2>Dashboard Overview</h2>
        <div className={classes.cardsWrapper}>
          <AmountsCard
            title="Today Orders"
            amountUSD={`$${todayOrders?.usd}`}
            amountLari={`${todayOrders?.gel}₾`}
            color="green"
          />
          <AmountsCard
            title="Yesterday Orders"
            amountUSD={`$${yesterdayOrders?.usd}`}
            amountLari={`${yesterdayOrders?.gel}₾`}
            color="orange"
          />
          <AmountsCard
            title="This Month"
            amountUSD={`$${thisMonthOrders?.usd}`}
            amountLari={`${thisMonthOrders?.gel}₾`}
            color="teal"
          />
          <AmountsCard
            title="All-Time Sales"
            amountUSD={`$${allTimeOrders?.usd}`}
            amountLari={`${allTimeOrders?.gel}₾`}
            color="blue"
          />
        </div>
        <div className={classes.cardsWrapper}>
          {numberOfTotalOrders.map((item) => (
            <NumberOfOrdersCard
              key={item.id}
              icon={item.icon}
              title={item.title}
              numberOfOrders={item.numberOfOrders}
            />
          ))}
        </div>
      </Flex>

      <Flex vertical gap={36}>
        <h2>Recent Orders Test</h2>
        <TableWrapper>
          <Table
            bordered
            scroll={{ x: "calc(700px + 30%)" }}
            pagination={{ pageSize: 10 }}
            columns={recentOrdersColumns}
            dataSource={tableDataTest}
          />
        </TableWrapper>
      </Flex>
    </Flex>
  );
};

export default Dashboard;
