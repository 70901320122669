import { ColumnsType } from "antd/es/table";
import { ParaglidingT } from "../Paragliding";
import { Flex, Tag } from "antd";
import { Link } from "react-router-dom";

export const paraglidingColumns: ColumnsType<ParaglidingT> = [
  {
    title: "Full Name",
    dataIndex: "fullName",
    key: "fullName",
    fixed: "left",
  },
  {
    title: "Phone Number",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
  },
  {
    title: "Amount of Participants",
    dataIndex: "participants",
    key: "participants",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "Paid Price",
    dataIndex: "paidPrice",
    key: "paidPrice",
    render: (value, rec) => {
      const hasToPayRenderer = () => {
        if (rec.currency.discountGEL) {
          const hasToPay = rec.currency.discountGEL - value;

          return <span>Left fee: {Number(hasToPay.toFixed(2))}</span>;
        }

        const hasToPay = rec.currency.gel - value;

        return <span>Left fee: {Number(hasToPay.toFixed(2))}</span>;
      };

      return (
        <Flex vertical gap={12}>
          <span>Paid: {value}₾</span>
          <span>{hasToPayRenderer()}₾</span>
        </Flex>
      );
    },
  },
  {
    title: "Payment Status",
    dataIndex: "status",
    key: "status",
    render: (value, rec) => {
      if (value.toLowerCase() === "rejected") {
        return (
          <Flex vertical gap={4}>
            <Tag color="red">{value.toUpperCase()}</Tag>
            <Link to={rec.orderDetails} target="_blank">
              Show Details
            </Link>
          </Flex>
        );
      }

      if (value.toLowerCase() === "pending") {
        return (
          <Flex vertical gap={4}>
            <Tag color="blue">{value.toUpperCase()}</Tag>
          </Flex>
        );
      }

      return (
        <Flex vertical gap={4}>
          <Tag color="green">{value.toUpperCase()}</Tag>
          <Link to={rec.orderDetails} target="_blank">
            Show Details
          </Link>
        </Flex>
      );
    },
  },
  {
    title: "Additional Details",
    dataIndex: "additionalDetails",
    key: "additionalDetails",
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    fixed: "right",
    width: 100,
  },
];
