import { useState } from "react";
import { Flex } from "antd";
import { useApiRequest } from "../../../../hooks/useApiRequests";
import useMessages from "../../../../hooks/useMessages";
import {
  updateGroupLesson,
  updateIndividualLesson,
  updateIndividualLocationInfo,
} from "../../../../api/services/skiSchoolServices";
import MediaCard from "../../../../components/MediaCard/MediaCard";
import MediaEditModal from "../../../../components/MediaEditModal/MediaEditModal";
import {
  GroupLessonProps,
  LocationInfoMediaCardProps,
} from "../../../../types/component-types/skiSchoolPageProps";
import { ItemT, LessonT, LocationInfoT, messageResT } from "src/api";
import { title } from "process";
import { cloudinaryUpload } from "src/lib/cloudinaryUpload";
import InfoItemsMediaCard from "src/components/SkiLessonsMediaCard/InfoItemsMediaCard";
import LocationInfoMediaCard from "src/components/SkiLessonsMediaCard/LocationInfoMediaCard";
import SkiLessonsMediaCard from "src/components/SkiLessonsMediaCard/SkiLessonsMediaCard";

const GroupLesson: React.FC<GroupLessonProps> = ({
  groupLessonData,
  getData,
}) => {
  const [editLessonDetail, setEditLessonDetail] =
    useState<LessonT>(groupLessonData);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [editValue, setEditValue] = useState<LocationInfoT>();
  const [itemEditValue, setItemEditValue] = useState<ItemT>();

  const [, makeUpdateRequest] = useApiRequest<messageResT>();
  const { error, success, contextHolder } = useMessages();

  const showEditModal = () => {
    setIsEditModalOpen(true);
    setEditLessonDetail(groupLessonData);
  };

  const handleOkEdit = () => {
    if (editLessonDetail) {
      setLoading(true);
      try {
        makeUpdateRequest(updateGroupLesson(editLessonDetail), (e) => {
          setLoading(false);
          setIsEditModalOpen(false);
          success(e.message);
          getData();
        });
      } catch (err) {
        setLoading(false);
        error("Something went wrong");
      }
    }
  };

  const handleCancelEdit = () => {
    setIsEditModalOpen(false);
  };

  const handleFieldChange = (fieldName: string, value: string) => {
    setEditLessonDetail((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const { locationInfo, items } = groupLessonData;

  const handleOnEdit = (id: string) => {
    const filteredValue = locationInfo.find((e) => e._id === id);
    setEditValue((prev) => {
      if (prev === filteredValue) return undefined;

      return filteredValue;
    });
  };

  const handleLocationUpdate = () => {
    try {
      if (editValue) {
        setLoading(true);
        makeUpdateRequest(
          updateIndividualLocationInfo(editValue, editValue._id),
          (e) => {
            success(e.message);
            setEditValue(undefined);
            getData();
          }
        );
        setLoading(false);
      }
    } catch (err) {
      error("Something went wrong");
    }
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setEditValue(
      (prev) =>
        ({
          ...prev,
          [name]: value,
        } as LocationInfoT)
    );
  };

  const handleItemEdit = (id: string) => {
    const filteredData = items.find((item) => item._id === id);

    setItemEditValue((prev) => {
      if (prev === filteredData) return undefined;

      return filteredData;
    });
  };

  const handleItemFieldChange = async (fieldName: string, value: any) => {
    let image: any;
    setLoading(true);
    if (value && fieldName === "image") {
      image = await cloudinaryUpload(value);
    }
    setItemEditValue(
      (prev) =>
        ({
          ...prev,
          [fieldName]: fieldName === "image" ? image : value,
        } as ItemT)
    );
    setLoading(false);
  };

  const handleItemUpdate = () => {
    try {
      if (itemEditValue) {
        setLoading(true);
        makeUpdateRequest(
          updateGroupLesson(
            {
              description: itemEditValue.description,
              image: itemEditValue.image,
            },
            itemEditValue._id
          ),
          (e) => {
            success(e.message);
            setItemEditValue(undefined);
            getData();
          }
        );
        setLoading(false);
      }
    } catch (e) {
      error("Something went wrong");
    }
  };

  const LoactionMediaCardProps: LocationInfoMediaCardProps = {
    data: locationInfo,
    editValue,
    handleChange,
    handleLocationUpdate,
    onEdit: handleOnEdit,
  };

  return (
    <>
      {contextHolder}
      <section>
        <Flex vertical gap={24}>
          <Flex vertical gap={12}>
            <h2>Group Lessons</h2>
            <SkiLessonsMediaCard
              data={groupLessonData}
              onEdit={showEditModal}
            />
          </Flex>

          <Flex vertical gap={12}>
            <h3>Location Info</h3>

            <LocationInfoMediaCard {...LoactionMediaCardProps} />
          </Flex>

          <Flex vertical gap={12}>
            <h3>Info Items</h3>

            <InfoItemsMediaCard
              data={items}
              loading={loading}
              itemEditValue={itemEditValue}
              handleFieldChange={handleItemFieldChange}
              onEdit={handleItemEdit}
              handleUpdate={handleItemUpdate}
            />
          </Flex>
        </Flex>
        <MediaEditModal
          isMediaEditModalOpen={isEditModalOpen}
          handleOk={handleOkEdit}
          handleCancel={handleCancelEdit}
          handleFieldChange={handleFieldChange}
          mediaDetails={editLessonDetail}
          isLoading={loading}
          containsImage={false}
        />
      </section>
    </>
  );
};

export default GroupLesson;
