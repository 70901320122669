import { Flex, Modal, Select } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import classes from "./EditModal.module.css";

type ParaglidingT = {
  _id: string;
  fullName: string;
  number: string;
  email: string;
  date: string;
  time: string;
  participants: string;
  additionalDetails: string;
  status: string;
  paidPrice: number | null;
  currency: {
    usd: number;
    gel: number;
    discountGEL: number | null;
    discountUSD: number | null;
  };
};

type EditModalT = {
  isEditModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  handleFieldChange: (dataIndex: string, value: any) => void;
  orderDetails: ParaglidingT;
};

export const EditModal = ({
  isEditModalOpen,
  handleOk,
  handleCancel,
  handleFieldChange,
  orderDetails,
}: EditModalT) => {
  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Modal
      title="Edit Order"
      open={isEditModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      className={classes.editModal}
      centered
    >
      <Flex vertical gap={12} className={classes.body}>
        <Flex gap={16} align="center">
          <p>Full Name: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("fullName", value);
              },
            }}
          >
            {orderDetails.fullName}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Number: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("number", value);
              },
            }}
          >
            {orderDetails.number}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Email: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("email", value);
              },
            }}
          >
            {orderDetails.email}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Date: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("date", value);
              },
            }}
          >
            {orderDetails.date.split("T")[0]}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Time: </p>
          <Select
            showSearch
            placeholder={orderDetails.time}
            popupMatchSelectWidth={false}
            optionFilterProp="children"
            onChange={(value) => {
              handleFieldChange("time", value);
            }}
            onSearch={onSearch}
            filterOption={filterOption}
            listHeight={150}
            options={[
              {
                value: "10 AM",
                label: "10 AM",
              },
              {
                value: "11 AM",
                label: "11 AM",
              },
              {
                value: "12 AM",
                label: "12 AM",
              },
              {
                value: "1 PM",
                label: "1 PM",
              },
              {
                value: "2 PM",
                label: "2 PM",
              },
              {
                value: "3 PM",
                label: "3 PM",
              },
              {
                value: "4 PM",
                label: "4 PM",
              },
              {
                value: "5 PM",
                label: "5 PM",
              },
            ]}
          />
        </Flex>

        <Flex gap={16} align="center">
          <p>Amount of Participants: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("participants", value);
              },
            }}
          >
            {orderDetails.participants}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Currency USD: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("currency_usd", value);
              },
            }}
          >
            {orderDetails.currency.usd}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Currency GEL: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("currency_gel", value);
              },
            }}
          >
            {orderDetails.currency.gel}
          </Paragraph>
        </Flex>

        {orderDetails.currency.discountGEL && (
          <Flex gap={16} align="center">
            <p>Currency GEL: </p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("currency_discount_gel", value);
                },
              }}
            >
              {orderDetails.currency.discountGEL}
            </Paragraph>
          </Flex>
        )}

        {orderDetails.currency.discountUSD && (
          <Flex gap={16} align="center">
            <p>Currency GEL: </p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("currency_discount_usd", value);
                },
              }}
            >
              {orderDetails.currency.discountUSD}
            </Paragraph>
          </Flex>
        )}

        <Flex gap={16} align="center">
          <p>Paid Price: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("paidPrice", value);
              },
            }}
          >
            {orderDetails.paidPrice}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Payment Status: </p>
          <Select
            showSearch
            placeholder={orderDetails.status}
            popupMatchSelectWidth={false}
            optionFilterProp="children"
            onChange={(value) => {
              handleFieldChange("status", value);
            }}
            onSearch={onSearch}
            filterOption={filterOption}
            options={[
              {
                value: "pending",
                label: "Pending",
              },
              {
                value: "completed",
                label: "Completed",
              },
              {
                value: "rejected",
                label: "Rejected",
              },
            ]}
          />
        </Flex>

        <Flex gap={16} align="center">
          <p>Additional Details: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("additionalDetails", value);
              },
            }}
          >
            {orderDetails.additionalDetails}
          </Paragraph>
        </Flex>
      </Flex>
    </Modal>
  );
};
