import React, { useRef, useState } from "react";
import classes from "./SkiLessonsMediaCard.module.css";
import { InfoItemsMediaCardProps } from "src/types";
import InfoItem from "./components/InfoItem";

const InfoItemsMediaCard: React.FC<InfoItemsMediaCardProps> = ({
  data,
  itemEditValue,
  onEdit,
  handleFieldChange,
  handleUpdate,
  loading,
}) => {
  return (
    <div className={classes.mediaCard}>
      <div className={classes.content}>
        {data.map((e) => (
          <InfoItem
            loading={loading}
            onEdit={onEdit}
            handleFieldChange={handleFieldChange}
            handleUpdate={handleUpdate}
            itemEditValue={
              itemEditValue
                ? itemEditValue
                : {
                    _id: "",
                    description: "",
                    image: {
                      public_id: "",
                      url: "",
                    },
                    warning: "",
                  }
            }
            {...e}
          />
        ))}
      </div>
    </div>
  );
};

export default InfoItemsMediaCard;
