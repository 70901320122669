import { Flex, Modal, Select } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import classes from "./EditModal.module.css";

type EditModalT = {
  isEditModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  handleFieldChange: (dataIndex: string, value: any) => void;
  orderDetails: {
    _id: string;
    name: string;
    number: string;
    email: string;
    age: string;
    lessonType: string;
    groupMembers: string;
    additional: string;
    activityType: string;
    experience: string;
    hours: string;
    fromDate: string;
    toDate: string;
    date: string;
    fromHour: string;
    status: string;
    paidPrice: number | null;
    currency: {
      usd: number;
      gel: number;
      discountGEL: number | null;
      discountUSD: number | null;
    };
    location: string;
  };
};

export const EditModal = ({
  isEditModalOpen,
  handleOk,
  handleCancel,
  handleFieldChange,
  orderDetails,
}: EditModalT) => {
  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Modal
      title="Edit Order"
      open={isEditModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      className={classes.editModal}
      centered
    >
      <Flex vertical gap={12} className={classes.body}>
        <Flex gap={16} align="center">
          <p>Name: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("name", value);
              },
            }}
          >
            {orderDetails.name}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Number: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("number", value);
              },
            }}
          >
            {orderDetails.number}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Email: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("email", value);
              },
            }}
          >
            {orderDetails.email}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Activity Type: </p>
          <Select
            showSearch
            // defaultValue={orderDetails.activityType}
            placeholder={orderDetails.activityType}
            popupMatchSelectWidth={false}
            optionFilterProp="children"
            onChange={(value) => {
              handleFieldChange("activityType", value);
            }}
            onSearch={onSearch}
            filterOption={filterOption}
            options={[
              {
                value: "Ski Lesson",
                label: "Ski Lesson",
              },
              {
                value: "Snowboard Lesson",
                label: "Snowboard Lesson",
              },
            ]}
          />
        </Flex>

        <Flex gap={16} align="center">
          <p>Lesson Type: </p>
          <Select
            showSearch
            placeholder={orderDetails.lessonType}
            popupMatchSelectWidth={false}
            optionFilterProp="children"
            onChange={(value) => {
              handleFieldChange("lessonType", value);
            }}
            onSearch={onSearch}
            filterOption={filterOption}
            options={[
              {
                value: "Individual",
                label: "Individual",
              },
              {
                value: "Group",
                label: "Group",
              },
            ]}
          />
        </Flex>

        <Flex gap={16} align="center">
          <p>Age: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("age", value);
              },
            }}
          >
            {orderDetails.age ? orderDetails.age : "-"}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Group Members: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("groupMembers", value);
              },
            }}
          >
            {orderDetails.groupMembers ? orderDetails.groupMembers : "1"}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Level of Experience: </p>
          <Select
            showSearch
            placeholder={orderDetails.experience}
            popupMatchSelectWidth={false}
            optionFilterProp="children"
            onChange={(value) => {
              handleFieldChange("experience", value);
            }}
            onSearch={onSearch}
            filterOption={filterOption}
            options={[
              {
                value: "Beginner",
                label: "Beginner",
              },
              {
                value: "Intermediate",
                label: "Intermediate",
              },
              {
                value: "Advance",
                label: "Advance",
              },
            ]}
          />
        </Flex>

        <Flex gap={16} align="center">
          <p>Date: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("date", value);
              },
            }}
          >
            {`${orderDetails.fromDate} - ${orderDetails.toDate}`}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>From Hour: </p>
          <Select
            showSearch
            placeholder={orderDetails.fromHour}
            popupMatchSelectWidth={false}
            optionFilterProp="children"
            onChange={(value) => {
              handleFieldChange("fromHour", value);
            }}
            onSearch={onSearch}
            filterOption={filterOption}
            listHeight={150}
            options={[
              {
                value: "10 AM",
                label: "10 AM",
              },
              {
                value: "11 AM",
                label: "11 AM",
              },
              {
                value: "12 AM",
                label: "12 AM",
              },
              {
                value: "1 PM",
                label: "1 PM",
              },
              {
                value: "2 PM",
                label: "2 PM",
              },
              {
                value: "3 PM",
                label: "3 PM",
              },
              {
                value: "4 PM",
                label: "4 PM",
              },
            ]}
          />
        </Flex>

        <Flex gap={16} align="center">
          <p>Duration: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("hours", value);
              },
            }}
          >
            {orderDetails.hours}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Location: </p>
          <Select
            showSearch
            placeholder={orderDetails.location}
            popupMatchSelectWidth={false}
            optionFilterProp="children"
            onChange={(value) => {
              handleFieldChange("location", value);
            }}
            onSearch={onSearch}
            filterOption={filterOption}
            options={[
              {
                value: "Marco Polo",
                label: "Marco Polo",
              },
              {
                value: "Gondola bottom",
                label: "Gondola bottom",
              },
              {
                value: "Gondola top",
                label: "Gondola top",
              },
              {
                value: "Gudauri Loft",
                label: "Gudauri Loft",
              },
              {
                value: "Ski Rental-Weski",
                label: "Ski Rental-Weski",
              },
            ]}
          />
        </Flex>

        <Flex gap={16} align="center">
          <p>Currency USD: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("currency_usd", value);
              },
            }}
          >
            {orderDetails.currency.usd}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Currency GEL: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("currency_gel", value);
              },
            }}
          >
            {orderDetails.currency.gel}
          </Paragraph>
        </Flex>

        {orderDetails.currency.discountGEL && (
          <Flex gap={16} align="center">
            <p>Currency GEL: </p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("currency_discount_gel", value);
                },
              }}
            >
              {orderDetails.currency.discountGEL}
            </Paragraph>
          </Flex>
        )}

        {orderDetails.currency.discountUSD && (
          <Flex gap={16} align="center">
            <p>Currency GEL: </p>
            <Paragraph
              style={{ margin: 0 }}
              editable={{
                onChange: (value) => {
                  handleFieldChange("currency_discount_usd", value);
                },
              }}
            >
              {orderDetails.currency.discountUSD}
            </Paragraph>
          </Flex>
        )}

        <Flex gap={16} align="center">
          <p>Paid Price: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("paidPrice", value);
              },
            }}
          >
            {orderDetails.paidPrice}
          </Paragraph>
        </Flex>

        <Flex gap={16} align="center">
          <p>Payment Status: </p>
          <Select
            showSearch
            placeholder={orderDetails.status}
            popupMatchSelectWidth={false}
            optionFilterProp="children"
            onChange={(value) => {
              handleFieldChange("status", value);
            }}
            onSearch={onSearch}
            filterOption={filterOption}
            options={[
              {
                value: "pending",
                label: "Pending",
              },
              {
                value: "completed",
                label: "Completed",
              },
              {
                value: "rejected",
                label: "Rejected",
              },
            ]}
          />
        </Flex>

        <Flex gap={16} align="center">
          <p>Additional: </p>
          <Paragraph
            style={{ margin: 0 }}
            editable={{
              onChange: (value) => {
                handleFieldChange("additional", value);
              },
            }}
          >
            {orderDetails.additional}
          </Paragraph>
        </Flex>
      </Flex>
    </Modal>
  );
};
